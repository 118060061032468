<template>
    <el-dialog title="选择区域" :visible.sync="showCity" width="920px" :before-close="handleClose">
        <div class="city-list">
            <div :class="['item', item.border ? 'border' : '']" v-for="(item, i) in cityList" :key="i"
                @mouseover="mouseover(i)" @mouseout="mouseout(i)">
                <el-checkbox @change="selectProvince(item, i)" :disabled="item.disabled" v-model="item.checked">
                    {{ item.name }}
                    <span v-show="item.num" class="num">（{{ item.num }}）</span>
                </el-checkbox>
                <div class="city" v-show="item.border && item._child">
                    <div class="child" v-for="(child, y) in item._child" :key="y">
                        <el-checkbox @change="selectCity(child, i, y)" v-model="child.checked">{{ child.name
                        }}</el-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelCity">取 消</el-button>
            <el-button type="primary" @click="selectArea">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            cityList: [],
            areaId: [],
        };
    },
    props: {
        showCity: {
            type: Boolean,
            default: !1,
        },
        //已经选择的所有的地区
        alreadyTotalCity: {
            default: [],
        },
    },
    created () {
        let alreadyTotalCity = this.alreadyTotalCity;
        this.$axios.get(this.$api.set.areaList, { tree: 1, level: 2 }).then((res) => {
            if (res.code == 0) {
                let list = res.result.list;
                for (let i in list) {
                    if (list[i].name == '天津市') list[i]._child = list[i]._child[0]._child;
                    if (list[i].name == '北京市') list[i]._child = list[i]._child[0]._child;
                    list[i].checked = !1;
                    list[i].border = !1;
                    let _child = list[i]._child || [];
                    list[i]._child_len = _child.length;
                    for (let y in list[i]._child) {
                        list[i]._child[y].checked = !1;
                    }
                }
                if (alreadyTotalCity.length) {
                    for (let i in alreadyTotalCity) {
                        for (let y in list) {
                            if (alreadyTotalCity[i].id == list[y].id) {
                                list[y].disabled = !0;
                                list[y].checked = !0;
                            }
                        }
                    }
                }
                this.cityList = list;
            }
        });
    },
    methods: {
        //选择省
        selectProvince (e, i) {
            let cityList = this.cityList;
            let city = cityList[i]._child || [];
            let areaId = this.areaId;
            if (e.checked) {
                for (let i in city) city[i].checked = !0;
                cityList[i].num = city.length;
                areaId.push(cityList[i]);
            } else {
                for (let i in city) city[i].checked = !1;
                cityList[i].num = 0;
                let arr = areaId.filter((i) => i.id != e.id);
                areaId = arr;
            }
            this.areaId = areaId;
        },
        //选择市
        selectCity (e, i, y) {
            let city = this.cityList[i]._child;
            let areaId = this.areaId;
            let filterCity = city.filter((i) => i.checked);
            if (filterCity.length) {
                this.cityList[i].checked = !0;
                this.cityList[i].num = filterCity.length;
            } else {
                this.cityList[i].num = 0;
                this.cityList[i].checked = !1;
            }
            areaId.push(this.cityList[i]);
            //根据id去重
            let arr = [];
            let obj = {};
            for (let i = 0; i < areaId.length; i++) {
                if (!obj[areaId[i].id]) {
                    arr.push(areaId[i]);
                    obj[areaId[i].id] = true;
                }
            }
            //选择市级城市为0时去除省级数据
            if (!filterCity.length) arr = arr.filter((item) => item.id != this.cityList[i].id);
            this.areaId = arr;
        },
        mouseover (i) {
            if (!this.cityList[i].disabled) {
                this.cityList[i].border = !0;
            }
        },
        mouseout (i) {
            this.cityList[i].border = !1;
        },
        selectArea () {
            this.$emit('getArea', this.areaId);
            this.cancelCity();
        },
        cancelCity () {
            this.$emit('closeCity');
        },
        handleClose () {
            this.$emit('closeCity');
        },
    },
};
</script>

<style lang="less" scoped>
.city-list {
    display: flex;
    flex-wrap: wrap;

    .item {
        margin-bottom: 10px;
        width: 160px;
        line-height: 30px;
        margin-right: 20px;
        padding-left: 10px;
        height: 30px;
        position: relative;

        &:nth-child(5),
        &:nth-child(10),
        &:nth-child(15),
        &:nth-child(20),
        &:nth-child(25),
        &:nth-child(30) {
            margin-right: 0;
        }

        .city {
            position: absolute;
            left: -1px;
            padding-left: 10px;
            top: 30px;
            z-index: 2;
            background: #dde8ff;
            border: 1px solid #409eff;
            width: 600px;
            display: flex;
            flex-wrap: wrap;

            .child {
                margin-right: 10px;
                width: 170px;
            }
        }
    }

    .border {
        border: 1px solid #409eff;
        background: #dde8ff;
    }
}</style>

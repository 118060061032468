<template>
  <el-main>
    <el-form ref="form" :model="form" :rules="rules" label-width="140px">
      <el-form-item label="运费模板名称：" prop="name">
        <el-input style="width: 500px" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="排序：">
        <el-input style="width: 200px" v-model="form.displayorder"></el-input>
        <div class="tips">数字越大，排名越靠前；如为空，排序默认为添加时间越近越靠前</div>
      </el-form-item>
      <el-form-item label="是否包邮：">
        <el-radio-group v-model="form.free_shipping">
          <el-radio :label="1">包邮</el-radio>
          <el-radio :label="2">部分条件包邮</el-radio>
          <el-radio :label="0">不包邮</el-radio>
        </el-radio-group>
        <el-form-item v-if="form.free_shipping == 2" prop="preferential_num">
          <div class="iscount">
            <el-select v-model="form.preferential_type">
              <el-option v-for="item in firstO" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <p class="full">满</p>
            <el-input type="primary" v-model="form.preferential_num"></el-input>
            <p class="full">{{ choosefirst == '0' ? '件' : '元' }}包邮</p>
          </div>
        </el-form-item>
      </el-form-item>
      <el-form-item v-if="form.free_shipping == 0 || form.free_shipping == 2" label="快递运费：">
        <el-radio-group v-model="form.pay_type">
          <el-radio :label="1">按件数</el-radio>
          <el-radio :label="2">按重量</el-radio>
        </el-radio-group>
        <div class="defaultpost">
          <p>默认运费（全国）</p>
          <div class="defaultitem">
            <span style="margin-right: 10px">{{ form.pay_type == 1 ? '首件数' : '首重量' }}</span>
            <el-form-item prop="default_freight.first_fee">
              <el-input type="primary" size="small" v-model="form.default_freight.first_fee"></el-input>
            </el-form-item>
            <span style="margin: 0 10px">{{ form.pay_type == 1 ? '件' : '克' }}， 首费用为</span>
            <el-form-item prop="default_freight.first_amount">
              <el-input type="primary" size="small" v-model="form.default_freight.first_amount"></el-input>
            </el-form-item>
            <span style="margin: 0 10px">元，{{ form.pay_type == 1 ? '续件数' : '续重量' }}</span>
            <el-form-item prop="default_freight.additional_fee">
              <el-input type="primary" size="small" v-model="form.default_freight.additional_fee"></el-input>
            </el-form-item>
            <span style="margin: 0 10px">{{ form.pay_type == 1 ? '件' : '克' }}， 续费为</span>
            <el-form-item prop="default_freight.additional_amount">
              <el-input type="primary" size="small" v-model="form.default_freight.additional_amount"></el-input>
            </el-form-item>
            <span style="margin-left: 10px">元</span>
          </div>
        </div>
        <div class="regionSet">
          <div class="regionItem" v-for="(item, index) in form.AreaList" :key="index">
            <p class="regionName">
              <span>{{ item.name.length > 30 && item.is_open ? item.name.slice(0, 30) + '...' : item.name }}</span>
              <template v-if="item.name.length > 30">
                <span v-if="item.is_open" class="zhankai" @click="item.is_open = !1">展开</span>
                <span v-else class="shouqi" @click="item.is_open = !0">收起</span>
              </template>
            </p>
            <div class="defaultitem">
              <span style="margin-right: 10px">{{ form.pay_type == 1 ? '首件数' : '首重量' }}</span>
              <el-form-item :prop="'AreaList.' + index + '.form.first_fee'" :rules="rules.freight_num">
                <el-input type="primary" size="small" v-model="item.form.first_fee"></el-input>
              </el-form-item>
              <span style="margin: 0 10px">{{ form.pay_type == 1 ? '件' : '克' }}， 首费用为</span>
              <el-form-item :prop="'AreaList.' + index + '.form.first_amount'" :rules="rules.freight_num">
                <el-input type="primary" size="small" v-model="item.form.first_amount"></el-input>
              </el-form-item>
              <span style="margin: 0 10px">元，{{ form.pay_type == 1 ? '续件数' : '续重量' }}</span>
              <el-form-item :prop="'AreaList.' + index + '.form.additional_fee'" :rules="rules.freight_num">
                <el-input type="primary" size="small" v-model="item.form.additional_fee"></el-input>
              </el-form-item>
              <span style="margin: 0 10px">{{ form.pay_type == 1 ? '件' : '克' }}， 续费为</span>
              <el-form-item :prop="'AreaList.' + index + '.form.additional_amount'" :rules="rules.freight_num">
                <el-input type="primary" size="small" v-model="item.form.additional_amount"></el-input>
              </el-form-item>
              <span style="margin-left: 10px">元</span>
              <i @click="removeCity(index)" class="el-icon-circle-close"></i>
            </div>
          </div>
        </div>
        <el-button @click="showCity = !0" class="detailarea" icon="el-icon-plus" plain size="small">指定地区设置运费</el-button>
      </el-form-item>
      <el-form-item label="是否默认：">
        <el-radio-group v-model="form.is_select">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="状态：">
        <el-radio-group v-model="form.is_able">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="0">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <Preservation @preservation="submit">
        <router-link to="/set/freightList" slot="return" style="margin-right: 10px">
          <el-button>返回</el-button>
        </router-link>
      </Preservation>
    </el-form>
    <City :alreadyTotalCity="alreadyTotalCity" :showCity="showCity" v-if="showCity" @closeCity="closeCity" @getArea="getArea"></City>
  </el-main>
</template>

<script>
import City from './components/city';
import Preservation from '@/components/preservation';

export default {
  components: {
    City,
    Preservation,
  },
  data() {
    const verify_freight_num = (rule, value, callback) => {
      if (!/^[\d|\.]*$/.test(value)) {
        callback(new Error('必须为数字'));
      }else{
         callback();
      }
    };
    return {
      showCity: !1,
      choosefirst: '0',
      form: {
        name: '',
        displayorder: '',
        pay_type: 1,
        preferential_num: '',
        preferential_type: '0',
        free_shipping: 2,
        is_select: 1,
        is_able: 1,
        areas: [],
        default_freight: {
          first_fee: '',
          additional_fee: '',
          first_amount: '',
          additional_amount: '',
        },
        AreaList: [],
      },
      alreadyTotalCity: [],
      firstO: [
        {
          value: '0',
          label: '按金额',
        },
        {
          value: '1',
          label: '按件数',
        },
      ],
      rules: {
        name: [
          {
            required: true,
            message: '请填写运费模板名称',
            trigger: 'blur',
          },
        ],
        preferential_num: [
          {
            required: true,
            message: '请填写包邮满足条件',
            trigger: 'blur',
          },
        ],
        'default_freight.first_fee': [
          {
            required: true,
            message: '必填',
            trigger: 'blur',
          },
          {
            validator: verify_freight_num,
            trigger: 'blur',
          },
        ],
        'default_freight.additional_fee': [
          {
            required: true,
            message: '必填',
            trigger: 'blur',
          },
          {
            validator: verify_freight_num,
            trigger: 'blur',
          },
        ],
        'default_freight.first_amount': [
          {
            required: true,
            message: '必填',
            trigger: 'blur',
          },
          {
            validator: verify_freight_num,
            trigger: 'blur',
          },
        ],
        'default_freight.additional_amount': [
          {
            required: true,
            message: '必填',
            trigger: 'blur',
          },
          {
            validator: verify_freight_num,
            trigger: 'blur',
          },
        ],
        freight_num: [
          {
            required: true,
            message: '必填',
            trigger: 'blur',
          },
          {
            validator: verify_freight_num,
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    let freightInfo = JSON.parse(localStorage.getItem('freightInfo'));
    let id = this.$route.query.id;
    if (id) {
      let utils = this.$store.state.utils;
      utils.page = Number(this.$route.query.page);
      utils.rows = Number(this.$route.query.rows);
      utils.is_record = !0;
      this.freightInfo = freightInfo;
      this.handleFreightInfo();
    }
  },
  methods: {
    closeCity() {
      this.showCity = !1;
    },
    //处理运费编辑所需要的信息
    handleFreightInfo() {
      let freightInfo = this.freightInfo;
      let form = this.form;
      let AreaList = form.AreaList;
      let areas_infos = freightInfo.areas_infos || [];
      if (areas_infos) areas_infos.splice(0, 1);
      form.name = freightInfo.name;
      form.displayorder = freightInfo.displayorder || '';
      form.pay_type = freightInfo.pay_type;
      form.preferential_num = freightInfo.preferential_num || '';
      form.preferential_type = String(freightInfo.preferential_type);
      form.free_shipping = freightInfo.free_shipping;
      form.is_select = freightInfo.is_select;
      form.is_able = freightInfo.is_able;
      form.default_freight = freightInfo.default_freight;
      form.id = freightInfo.id;
      form.is_select ? (form.is_select = 1) : (form.is_select = 0);
      for (let i in areas_infos) {
        let _child = [];
        let name = '';
        let obj = {
          first_fee: areas_infos[i].first_fee,
          additional_fee: areas_infos[i].additional_fee,
          first_amount: areas_infos[i].first_amount,
          additional_amount: areas_infos[i].additional_amount,
          area_ids: areas_infos[i].area_ids,
          ids: [],
        };
        if (areas_infos[i].province_detail) this.alreadyTotalCity.push(...areas_infos[i].province_detail);
        _child.push(...areas_infos[i].area_detail);
        for (let y in _child) {
          name += _child[y].name + ';';
        }
        areas_infos[i]._child = _child;
        areas_infos[i].form = obj;
        areas_infos[i].area = [];
        areas_infos[i].name = name;
        areas_infos[i].is_open = !0;
      }
      AreaList.push(...areas_infos);
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let form = JSON.parse(JSON.stringify(this.form));
          let AreaList = form.AreaList;
          let url = form.id ? this.$api.set.freightEdit : this.$api.set.freightAdd;
          form.areas = [];
          for (let i in AreaList) form.areas.push(AreaList[i].form);
          for (let i in form.areas) {
            for (let y in AreaList) {
              let area = AreaList[y].area;
              let _child = AreaList[y]._child;
              if (i == y) {
                if (area.length) {
                  for (let k in area) {
                    form.areas[i].area_ids.push(area[k].id);
                    form.areas[i].area_ids = [...new Set(form.areas[i].area_ids)];
                  }
                }
                for (let k in _child) {
                  form.areas[i].ids.push(_child[k].id);
                  form.areas[i].ids = [...new Set(form.areas[i].ids)];
                }
              }
            }
          }
          if (form.free_shipping != 2) delete form.preferential_num;
          delete form.AreaList;
          this.$axios.post(url, form).then(res => {
            if (res.code == 0) {
              localStorage.removeItem('freightInfo');
              this.$message.success(`${form.id ? '编辑成功' : '添加成功'}`);
              this.$router.push('/set/freightList');
            } else {
              this.$message({
                message: res.msg,
                type: 'warning',
              });
            }
          });
        }
      });
    },
    //删除地址
    removeCity(index) {
      let area = this.form.AreaList[index].area;
      let alreadyTotalCity = this.alreadyTotalCity;
      for (let i in area) {
        for (let y in alreadyTotalCity) {
          if (alreadyTotalCity[y].id == area[i].id) alreadyTotalCity.splice(y, 1);
        }
      }
      this.form.AreaList.splice(index, 1);
    },
    getArea(val) {
      if (val.length) {
        this.alreadyTotalCity.push(...val);
        let arr = [];
        let obj = {
          form: {
            first_fee: '',
            additional_fee: '',
            first_amount: '',
            additional_amount: '',
            area_ids: [],
            ids: [],
          },
          area: val,
          name: '',
          is_open: !0,
          _child: [],
        };
        arr.push(obj);
        for (let i in arr) {
          for (let y in arr[i].area) {
            arr[i].area[y]._child = arr[i].area[y]._child || [];
            if (arr[i].area[y].name == '台湾省') arr[i].name += arr[i].area[y].name + ';';
            let _child = arr[i].area[y]._child.filter(item => item.checked);
            for (let c in _child) {
              arr[i].name += _child[c].name + ';';
              arr[i]._child.push(_child[c]);
            }
          }
        }
        this.form.AreaList.push(...arr);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  color: #333;
  font-size: 14px;
  height: 100%;
  .tips {
    color: #999;
  }
  .iscount {
    display: flex;
    .full {
      margin: 0 10px;
      box-sizing: border-box;
    }
    .el-select {
      width: 160px;
    }
    .el-input {
      width: 250px;
      margin-right: 10px;
    }
  }
  .defaultpost {
    display: flex;
    margin-bottom: 20px;
    p:first-child {
      display: flex;
      align-items: center;
      width: 150px;
      color: #999;
    }
    .defaultitem {
      display: flex;
      align-items: center;
    }
    .el-input {
      width: 70px;
    }
    .areaname {
      width: 100px;
      height: 50px;
      border: 1px solid red;
      margin-right: 10px;
    }
  }
  .regionSet {
    margin-bottom: 20px;
    .regionItem {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
      align-items: center;
      .regionName {
        width: 150px;
        padding-right: 10px;
        line-height: 25px;
        box-sizing: border-box;
        .zhankai,
        .shouqi {
          color: #409eff;
          margin-left: 5px;
          cursor: pointer;
        }
      }
      .defaultitem {
        display: flex;
        align-items: center;
        position: relative;
        .el-icon-circle-close {
          font-size: 22px;
          position: absolute;
          right: -40px;
          top: 5px;
        }
        .el-input {
          width: 70px;
        }
      }
    }
  }
  .detailarea {
    border: 1px solid #409eff;
    color: #409eff;
  }
}
</style>
